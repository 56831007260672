import React from "react"
import { Link } from "gatsby"
import "./style.scss"

const Software = () => {
  const TECH_SOFT = [
    {
      id: "saas",
      title: "I nostri prodotti",
      to: "/",
      children: [
        {
          title: "Book@Me",
          payoff: "easy to book",
          description:"booking engine con channel manager in cloud",
          to: "/tech-soft/saas/bookatme",
        },
        {
          title: "Connect@You",
          payoff: "WiFi is our hit",
          description:"servizio di hotspot, WiFi gratuita, facile e sicura",
          to: "/tech-soft/saas/connectatyou",
        },
        {
          title: "Eucleia",
          payoff: "la tua reputazione online",
          description: "software in cloud per accrescere la reputazione online",
          to: "/tech-soft/saas/eucleia",
        },
        { title: "Paiazo", 
          payoff: "smart menu e gestione degli ordini",
          description:"", 
          to: "/tech-soft/saas/paiazo" 
        },
        {
          title: "Wellify",
          payoff: "software gestionale in cloud per parrucchieri ed estetisti",
          description:"",
          to: "/tech-soft/saas/wellify",
        },
      ],
    },
    {
      id: "custom",
      title: "APP & Software Personalizzati",
      to: "/",
      children: [
        {
          title: "Lorenzo “Jovanotti” Cherubini",
          payoff: "Jova Beach Party Tour",
          to: "/tech-soft/custom/jovabeachparty",
        },
        {
          title: "Pinguini Tattici Nucleari ",
          payoff: "#machilavrebbemaidetto Tour",
          to: "/tech-soft/custom/ptn",
        },
        {
          title: "Riccione Christmas Village",
          payoff: "Il parco tematico dedicato al Natale",
          to: "/tech-soft/custom/riccionechristmas",
        },
      ],
    },
    {
      id: "it",
      title: "Infrastrutture reti",
      to: "/",
      children: [
        {
          title: "Bagno Tiki 26",
          payoff: "stabilimento balneare",
          to: "/tech-soft/wifi/bagnotiki26",
        },
        {
          title: "Castello di Baccaresca",
          payoff: "hotel/resort/spa",
          to: "/tech-soft/wifi/baccaresca",
        },
        {
          title: "Corona Sunsets Festival",
          payoff: "festival",
          to: "/tech-soft/wifi/corona",
        },
        {
          title: "Domus Pacis",
          payoff: "hotel/centro congressi",
          to: "/tech-soft/wifi/domuspacis",
        },
        {
          title: "Eurochocolate",
          payoff: "manifestazione gastronomica",
          to: "/tech-soft/wifi/eurochocolate",
        },
        {
          title: "Ferrara Sharing Festival",
          payoff: "meeting economico-finanziario",
          to: "/tech-soft/wifi/ferrara",
        },
        {
          title: "Gluten Free Fest",
          payoff: "manifestazione eno-gastronomica",
          to: "/tech-soft/wifi/glutenfreefest",
        },
        {
          title: "International Motor Days",
          payoff: "manifestazione sportiva",
          to: "/tech-soft/wifi/motordays",
        },
        {
          title: "Le Befane Shopping Centre",
          payoff: "centro commerciale",
          to: "/tech-soft/wifi/lebefane",
        },
        {
          title: "Malto Livello",
          payoff: "manifestazione eno-gastronomica",
          to: "/tech-soft/wifi/maltolivello",
        },
        {
          title: "Pane Nostrum",
          payoff: "manifestazione eno-gastronomica",
          to: "/tech-soft/wifi/panenostrum",
        },
        {
          title: "Piacere Barbecue",
          payoff: "manifestazione eno-gastronomica",
          to: "/tech-soft/wifi/piacerebarbecue",
        },
        {
          title: "Rete WiFi Città di Riccione ",
          payoff: "rete pubblica cittadina",
          to: "/tech-soft/wifi/riccione",
        },
        {
          title: "Rossopomodoro",
          payoff: "ristorante/pizzeria",
          to: "/tech-soft/wifi/rossopomodoro",
        },
        {
          title: "Todi Appy Days",
          payoff: "meeting tecnologico",
          to: "/tech-soft/wifi/todiappydays",
        },
      ],
    },
  ]

  return (
    <>
      {TECH_SOFT.map(category => (
        <div className="techSoft" key={category.id}>
          <div className="allSoft">
            <h2>{category.title}</h2>
          </div>
          {category.children.map(software => (
            <Link key={software.title} to={software.to}>
              <div className="soft">
                <p>
                  <b>{software.title}</b>
                  <br />
                  {software.payoff}
                  <br/>
                  {software.description}
                </p>
                {/* <span>App Development</span> */}
              </div>
            </Link>
          ))}
        </div>
      ))}
    </>
  )
}

export default Software
